

















import { AuthModule } from "@/store/modules";
import { RootRouter } from "@/utils/newRoutePathConstant";
import { Component, Vue } from "vue-property-decorator";
import helper from "@/utils/helpers";

@Component
export default class GoogleLogin extends Vue {
  accessToken: string = "";
  state: string = "";
  message: string = "Trying to login using google";

  redirect() {
    let profile = helper.getUserProfile() as any;
    if (profile && profile.id) {
      if (profile.userType) {
        this.$router.push(RootRouter.NewHome);
      } else {
        this.$router.push({ name: "NewHome" });
      }
    }
  }

  parseHash(query: string) {
    var res: any = {};

    query = query.trim().replace(/^(\?|#|&)/, "");

    if (!query) {
      return res;
    }

    query.split("&").forEach(function(param) {
      var parts = param.replace(/\+/g, " ").split("=");
      console.log(parts);

      var key = parts[0];
      var val = parts.length > 0 ? parts[1] : null;

      if (res[key] === undefined) {
        res[key] = val;
      } else if (Array.isArray(res[key])) {
        res[key].push(val);
      } else {
        res[key] = [res[key], val];
      }
    });

    return res;
  }

  async created() {
    let hash = this.parseHash(this.$route.hash);
    this.accessToken = hash.access_token as string;
    this.state = hash.state as string;
    const state = localStorage.getItem("state");
    if (
      this.state &&
      this.state == state &&
      this.accessToken &&
      this.accessToken != ""
    ) {
      await AuthModule.googleLogin({ accessToken: this.accessToken });
      this.redirect();
    } else {
      this.message = "Could not login through google";
    }
    localStorage.removeItem("state");
  }

  onLoginClick() {
    this.$bvModal.show("login");
  }
}
